export const BASE_DOMAIN = "https://b.jimmylv.cn";
export const CHECKOUT_URL =
  "https://jimmylv.lemonsqueezy.com/checkout/buy/e0c93804-abcc-47f7-848c-8756bec0e2fb";
export const LOGIN_LIMIT_COUNT = 50;
export const FREE_LIMIT_COUNT = 50;
export const RATE_LIMIT_COUNT = LOGIN_LIMIT_COUNT + FREE_LIMIT_COUNT;

export const FADE_IN_ANIMATION_SETTINGS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.2 },
};
